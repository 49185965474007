<template>
  <div style="margin-top:50px">
    <div class="vx-row mb-6" style="width: 100%">
      <div :class="'vx-col w-full sm:w-2/12'" style="font-weight: bold;">
          <span>{{setHeadTable()[0].title }}</span>
      </div>
      <!-- <div :class="'vx-col w-full sm:w-1/12'" style="font-weight: bold;">
          <span>{{setHeadTable()[1].title }}</span>
      </div>
      <div :class="'vx-col w-full sm:w-1/12'" style="font-weight: bold;">
          <span>{{setHeadTable()[2].title }}</span>
      </div> -->
      <div :class="'vx-col w-full sm:w-2/12'" style="font-weight: bold;">
          <!-- <span>{{setHeadTable()[3].title }}</span> -->
          <span>{{setHeadTable()[1].title }}</span>
      </div>
      <div :class="'vx-col w-full sm:w-2/12'" style="font-weight: bold;">
          <!-- <span>{{setHeadTable()[4].title }}</span> -->
          <span>{{setHeadTable()[2].title }}</span>
      </div>
      <div :class="'vx-col w-full sm:w-2/12'" style="font-weight: bold;">
          <!-- <span>{{setHeadTable()[5].title }}</span> -->
          <span>{{setHeadTable()[3].title }}</span>
      </div>
      <div :class="'vx-col w-full sm:w-2/12'" style="font-weight: bold;">
          <!-- <span>{{setHeadTable()[6].title }}</span> -->
          <span>{{setHeadTable()[4].title }}</span>
      </div>
      <!-- <div :class="'vx-col w-full sm:w-1/12'" style="font-weight: bold;">
          <span>{{setHeadTable()[5].title }}</span>
      </div>
      <div :class="'vx-col w-full sm:w-1/12'" style="font-weight: bold;">
          <span>{{setHeadTable()[6].title }}</span>
      </div> -->
      <div class="vx-col sm:w-1/12 w-full" v-show="itemRefs[0] ? itemRefs[0].action : false">
        <span></span>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 100%" :key="index"
        v-for="(dt, index) in itemRefs"
        :state="dt.state">

        <div :class="'vx-col w-full sm:w-2/12'" style="font-weight: bold;">
          <formInputs
            :components="dt.inputs[0].components"
            @oninputitem="oninputitem" @datepickerOpened="datepickerOpened" @datepickerClosed="datepickerClosed"
          />
        </div>
        <!-- <div :class="'vx-col w-full sm:w-1/12'" style="font-weight: bold;">
            <formInputs
            :components="dt.inputs[1].components"
            @oninputitem="oninputitem" @datepickerOpened="datepickerOpened" @datepickerClosed="datepickerClosed"
          />
        </div>
        <div :class="'vx-col w-full sm:w-1/12'" style="font-weight: bold;">
            <formInputs
            :components="dt.inputs[2].components"
            @oninputitem="oninputitem" @datepickerOpened="datepickerOpened" @datepickerClosed="datepickerClosed"
          />
        </div> -->
        <div :class="'vx-col w-full sm:w-2/12'" style="font-weight: bold;">
            <formInputs
            :components="dt.inputs[1].components"
            @oninputitem="oninputitem" @datepickerOpened="datepickerOpened" @datepickerClosed="datepickerClosed"
          />
        </div>
        <div :class="'vx-col w-full sm:w-2/12'" style="font-weight: bold;">
            <formInputs
            :components="dt.inputs[2].components"
            @oninputitem="oninputitem" @datepickerOpened="datepickerOpened" @datepickerClosed="datepickerClosed"
          />
        </div>
        <div :class="'vx-col w-full sm:w-2/12'" style="font-weight: bold;">
            <formInputs
            :components="dt.inputs[3].components"
            @oninputitem="oninputitem" @datepickerOpened="datepickerOpened" @datepickerClosed="datepickerClosed"
          />
        </div>
        <div :class="'vx-col w-full sm:w-2/12'" style="font-weight: bold;">
            <formInputs
            :components="dt.inputs[4].components"
            @oninputitem="oninputitem" @datepickerOpened="datepickerOpened" @datepickerClosed="datepickerClosed"
          />
        </div>
        <!-- <div :class="'vx-col w-full sm:w-1/12'" style="font-weight: bold;">
            <formInputs
            :components="dt.inputs[5].components"
            @oninputitem="oninputitem" @datepickerOpened="datepickerOpened" @datepickerClosed="datepickerClosed"
          />
        </div>
        <div :class="'vx-col w-full sm:w-1/12'" style="font-weight: bold;">
            <formInputs
            :components="dt.inputs[6].components"
            @oninputitem="oninputitem" @datepickerOpened="datepickerOpened" @datepickerClosed="datepickerClosed"
          />
        </div> -->

        <div class="vx-col sm:w-1/12 w-full">
          <div v-if="index == 0"  v-show="dt.action">
            <vx-tooltip text="Add Reference">
              <vs-button
                size="small"
                color="green"
                icon-pack="feather"
                icon="icon-plus"
                @click="handleAddItemRef()"
              />
            </vx-tooltip>
          </div>
          <div v-else  v-show="dt.action">
            <vx-tooltip text="Delete Reference">
              <vs-button
                size="small"
                color="red"
                icon-pack="feather"
                icon="icon-x"
                @click="handleRemoveItemRef(index)"
              />
            </vx-tooltip>
          </div>
        </div> 
    </div>
  </div>
  </template>
  
<script>
import moment from "moment";
import formInputs from "./form_inputs.vue";

export default {
  components: {
    formInputs,
  },
  props: ["itemRefs", "modelItemRefInput"],
  mounted() {},
  data() {
    return {
      table: this.tableDefaultState(),
    };
  },
  watch: {
    watchedProperties: function () {
      // this.getData();
      this.setHeadTable();
    },
  },
  computed: {
    // watchedProperties() {
    //   return `${this.territories}||${this.docRefType}||${this.search}||${this.supplier}||${this.filterDate.startDate}||${this.filterDate.endDate}||${this.filterPostingDate.startDate}||${this.filterPostingDate.endDate}`;
    // },
    // setPage: {
    //   get() {
    //     return 1;
    //   },
    //   set(val) {
    //     this.handleChangePage(val);
    //   },
    // },
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        heads: [
          { title: "Reference", field: "sales_office_code" },
          { title: "Start Date", field: "sales_office_desc" },
          { title: "End Date", field: "vendor_code" },
          { title: "Prefix", field: "vendor_name" },
          { title: "Start Number", field: "purchase_org_code" },
          { title: "End Number", field: "purchase_org_name" },
          { title: "Total SN", field: "debit_note_number" },
          { title: "Total SN Allocated", field: "doc_type" },
          { title: "Total SN Remaining", field: "doc_ref_type" },
        ],
      };
    },
    oninputitem(data, type) {
      this.$emit("input-itemref", data, type);
    },
    handleClose() {
      this.getData();
      this.$emit("close");
    },
    handleAddItemRef() {
      this.$emit("update-itemref", this.tmpItemRefsFunc(), "add");
    },
    tmpItemRefsFunc() {
      const itemRef = JSON.parse(JSON.stringify(this.modelItemRefInput));
      return itemRef;
    },
    setHeadTable() {
      const items = this.tmpItemRefsFunc().inputs;
      var heads = [];
      // console.log("heads", items);
      if (items) {
        items.forEach((element) => {
          heads.push({
            title: element.components.title,
            field: element.components.name,
          });
        });
      }
      return heads;
    },
    handleRemoveItemRef(index) {
      this.$emit("update-itemref", index, "remove");
    },
    handleSearch(searching) {
      console.log(searching);
    },
    dateFormat(val) {
      return moment(val).format("DD MMM YYYY");
    },
    getInputs() {
      var inputs = [];
      const today = new Date();
      var startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDay()
      );
      var endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDay() + 1
      );
      inputs.push({
        components: {
          disabled: false,
          validate: "required",
          name: "reference",
          placeholder: "Reference",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          disabled: false,
          validate: "required",
          name: "start_date",
          placeholder: "Start Date",
          type: "date",
          value: startDate,
        },
      });
      inputs.push({
        components: {
          disabled: false,
          validate: "required",
          name: "end_date",
          placeholder: "End Date",
          type: "date",
          value: endDate,
        },
      });
      inputs.push({
        components: {
          disabled: false,
          validate: "required",
          name: "prefix",
          placeholder: "Prefix",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          disabled: false,
          validate: "required",
          name: "start_number",
          placeholder: "Start Number",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          disabled: false,
          validate: "required",
          name: "end_number",
          placeholder: "End Number",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          disabled: false,
          validate: "required",
          name: "total_sn",
          placeholder: "Total SN",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          disabled: false,
          validate: "required",
          name: "total_allocation",
          placeholder: "Total Allocation",
          type: "input",
          value: "",
        },
      });
      inputs.push({
        components: {
          disabled: false,
          validate: "required",
          name: "total_remaining",
          placeholder: "Total Remaining",
          type: "input",
          value: "",
        },
      });
      return inputs;
    },
    formatPrice(val) {
      if (isNaN(val)) {
        val = 0;
      }
      val = (val / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    format(head, value) {
      if (typeof head["format"] === "function") {
        var f = head["format"];
        return f(value);
      }
      return value;
    },
    datepickerOpened(components){
      this.$emit("date-pickerOpened",components);
    },
    datepickerClosed(components){
      this.$emit("date-pickerClosed",components);
    },
  },
};
</script>
  
<style>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
.core-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.vs-table--content {
  overflow-y: visible !important;
  overflow-x: visible !important;
}
.vs-con-table .vs-con-tbody {
  overflow-y: visible !important;
  overflow-x: visible !important;
}
.vs-table--tbody-table
  .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
  -webkit-transform: none;
  transform: none;
}
</style>
