<template>
  <div class="w-full">
    <vs-input v-if="components.type == 'input'" :class="'w-full ' + this.components.text_align ? components.text_align : ''
      " v-validate="components.validate" :name="components.name" v-model="components.value" :style="{
        width: components.width
          ? components.width + 'px !important'
          : 100 + '%',
      }" :disabled="components.disabled" :placeholder="components.placeholder" @keypress="keypress($event)"
      @input="oninputitem" />

    <vs-input v-if="(components.type == 'text' && edit)" :class="'w-full ' + this.components.text_align ? components.text_align : ''
      " v-validate="components.validate" :name="components.name" v-model="components.temp_value" :style="{
        width: components.width
          ? components.width + 'px !important'
          : 100 + '%',
      }" :disabled="components.disabled" :placeholder="components.placeholder" @keypress="keypress($event)"
      @input="oninputitem" />

    <vs-textarea v-else-if="components.type == 'textarea'" v-validate="components.validate" v-model="components.value"
      :disabled="components.disabled" />
    <div v-else-if="components.type == 'checkbox'">
      <vs-checkbox v-if="components.head == 'checkbox'" v-model="components.head_val"
        @input="oninputitem"></vs-checkbox>
      <vs-checkbox v-else :disabled="this.components.disabled" v-model="components.value"
        @input="oninputitem"></vs-checkbox>
    </div>
    <div v-else-if="components.type == 'text'">
      <span>{{ components.value }}</span>
    </div>
    <div v-else-if="components.type == 'text-link' && !components.handler_event">
      <a :href="components.href ? components.href : '#'" :target="components.target ? components.target : ''">{{
        components.value }}</a>
    </div>

    <div v-else-if="components.type == 'text-link' && components.handler_event">
      <a :href="components.href ? components.href : '#'" :target="components.target ? components.target : ''"
        @click="handlerClick">{{ components.value }}</a>
    </div>

    <multiselect v-else-if="components.type == 'multiselect'" class="selectExample" v-model="components.value"
      :options="components.option" :multiple="components.multiple" :allow-empty="components.allowempty"
      :group-select="false" :max-height="160"
      :internal-search="components.internal_search != undefined ? components.internal_search : true"
      @search-change="handlerSearchMultiselect" :limit="4" :placeholder="components.placeholder" @input="onselected"
      :track-by="components.track_by" :disabled="components.disabled" label="text">
      <template slot="singleLabel" slot-scope="dt">
        <span class="option__desc">
          <span class="option__title">
            {{ dt.option.text }}
          </span>
        </span>
      </template>

      <template slot="option" slot-scope="dt">
        <div class="option__desc">
          <span class="option__title">
            {{ dt.option.text }}
          </span>
        </div>
      </template>
    </multiselect>

    <datepicker v-else-if="components.type == 'date'" :inline="false" v-model="components.value"
      :format="components.format ? components.format : 'yyyy-MM-dd'" :input-class="'form-control'"
      :minimum-view="components.min_view ? components.min_view : ''" :placeholder="components.placeholder"
      :disabled-dates="components.disabled_dates" @focusin.native="datepickerOpened" @focusout.native="datepickerClosed"
      @selected="onselected">
    </datepicker>

    <date-range-picker v-else-if="components.type == 'daterange'" style="min-height: 40px" class="w-full" ref="picker"
      opens="center" :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }" singleDatePicker="range" :timePicker="false"
      :timePicker24Hour="false" :showWeekNumbers="false" :showDropdowns="false" :autoApply="true"
      v-model="components.value" :linkedCalendars="false" :ranges="false" @select="logDaterange"
      :min-date="components.minDate" :max-date="components.maxDate" :date-format="components.dateformat">
      <template v-slot:input="picker">
        {{ datePickerDateFormat(picker.startDate) }} - {{ datePickerDateFormat(picker.endDate) }}
      </template>
    </date-range-picker>

    <vs-button v-else-if="components.type == 'button'" icon-pack="feather" :color="components.color"
      :icon="components.icon" @click="handlerClick" :disabled="components.disabled" :class="components.class">{{
        components.text }}</vs-button>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from "moment";
export default {
  components: {
    Datepicker,
    DateRangePicker,
  },
  props: ["components", "edit"],
  data() {
    return {
      dt: "",
      checkboxVal: () => {
        return this.components.head == "checkbox"
          ? this.components.head_val
          : this.components.value;
      },
    };
  },
  mounted() {
    if (this.components.type == 'checkbox') {

    }

  },
  methods: {
    logDaterange(val) {
      console.log("date range", val)
      this.onselected(val)
    },
    datePickerDateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("dddd, MMMM Do YYYY");
      }
      return a;
    },
    oninputitem() {
      this.$emit("oninputitem", this.components, "itemref");
    },
    onselected(select) {
      this.$emit("update-forminput", select, this.components);
    },
    datepickerOpened(q) {
      this.$emit("datepickerOpened", this.components);
    },
    datepickerClosed(q) {
      this.$emit("datepickerClosed", this.components);
    },
    handlerSearchMultiselect(s) {
      // console.log("components.internal_search?components.internal_search:true,",this.components.internal_search!=undefined?this.components.internal_search:true)
      this.$emit("handlerSearch", this.components, s);
    },
    handlerClick(e) {
      e.preventDefault()
      this.$emit("click", this.components);
    },
    keypress(evt) {
      if (
        this.components.name == "start_number" ||
        this.components.name == "end_number"
      ) {
        return (this.isNumber(evt) && this.maxLength(evt, 8));
      }
      if (this.components.name == "sequence") return (this.isNumber(evt) && this.maxLength(evt, 2))
      if (this.components.name == "prefix") return (this.isNumber(evt) && this.maxLength(evt, 3))
      return true;
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        !(charCode > 47 && charCode < 58)
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    maxLength(evt, max) {
      var length = this.components.value.length
      console.log(length)
      console.log((length + 1) <= max)
      if ((length + 1) <= max) {
        return true
      } else {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          // text: "value must be less than "+max+" characters",
          text: "value cannot more than " + max + " characters",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        evt.preventDefault()
      }
    }
  },
};
</script>
<style>
.text-center input {
  text-align: center !important;
}
</style>